body,
label,
p,
span,
button,
th span,
a {
  font-family: "Overpass", sans-serif !important;
  font-size: 1rem !important;
}

.MuiTypography-body1 {
  font-family: "Overpass", sans-serif !important;
}

#loginPage {
  background: #000 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center !important;
}

body .layout {
  background: white !important;
}

.MuiAppBar-colorSecondary {
  background-color: white !important;
  color: #000 !important;
}

.MuiDrawer-docked > div {
  width: 350px !important;
}
.MuiSwitch-colorPrimary.MuiSwitch-checked {
  color: #000 !important;
}
.MuiRadio-colorPrimary.MuiRadio-checked {
  color: #000 !important;
}

.MuiFormHelperText-root {
  font-family: "Overpass", sans-serif !important;
  font-size: 0.8rem !important;
}
